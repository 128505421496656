import React, { useState, forwardRef } from "react"
import DatePicker from "shared/components/DatePicker"
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom"
import Text from "shared/components/Text"
import Box from "shared/components/Box"
import { sm, md } from "mill/utils/breakpoints"

const Container = styled.div`
  span {
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    font-size: 1.6rem;

    @media ${sm} {
      font-size: 1.8rem;
    }

    @media ${md} {
      font-size: 2rem;
    }
  }
`

const DatePickerContainer = styled.div`
  @media ${md} {
    display: grid;
    grid-template-columns: 1fr 3.8rem 1fr;

    > span {
      margin: 0 1.5rem;
    }
  }
`

const CustomDatePicker = ({ parameter, defaultDate }) => {
  let navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [startDate, setStartDate] = useState(defaultDate)

  const handleDateChange = date => {
    const localDate = date.toISOString().split("T")[0]
    setStartDate(date)
    query.delete(parameter)
    navigate(
      `${location.pathname}?${query.toString()}&${parameter}=${localDate}`
    )
  }

  return (
    <DatePicker withIcon selected={startDate} onChange={handleDateChange} />
  )
}

const DateRangeSelector = ({ label, fallbackStart, fallbackEnd }) => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const activeEnd =
    (query.get("activeEnd") && new Date(query.get("activeEnd"))) || fallbackEnd
  const activeStart =
    (query.get("activeStart") && new Date(query.get("activeStart"))) ||
    fallbackStart

  return (
    <Container>
      <Box paddingTop="medium">
        <Text as="label" weight="xstrong" tone="dark">
          {label}
        </Text>
        <DatePickerContainer>
          <CustomDatePicker parameter="activeStart" defaultDate={activeStart} />{" "}
          <span>-</span>
          <CustomDatePicker parameter="activeEnd" defaultDate={activeEnd} />
        </DatePickerContainer>
      </Box>
    </Container>
  )
}

DateRangeSelector.defaultProps = {
  label: "Campaigns active between"
}

export default DateRangeSelector